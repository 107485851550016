@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.style-sample-review {
  &__compare {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: top;
    justify-content: center;
    position: relative;
    margin-top: 0;
    @media (max-width: $tablet-breakpoint) {
      grid-template-columns: 1fr;
    }
  }

  &__left-col {
    padding-right: 1rem;
  }

  &__right-col {
    background-color: $white;
    position: relative;

    @media (max-width: $tablet-breakpoint) {
      padding-left: 0;
    }
    &::after {
      content: '';
      position: absolute;
      width: 1rem;
      background: $white;
      z-index: 10;
      left: -1rem;
      top: 0;
      height: 100%;
      @media (max-width: $tablet-breakpoint) {
        content: none;
      }
    }

    &::before {
      content: '';
      background-color: $white;
      width: 50%;
      position: absolute;
      height: 100%;
      z-index: 0;
      right: -130px;
      top: 0;

      @media (max-width: $tablet-breakpoint) {
        right: -1rem;
        width: 110%;
      }
    }
  }
  &__heading {
    span {
      @include style-subtitle;
      margin-left: 2rem;
    }
    .dashboard-nav--light-mode {
      background-color: unset;
      border-bottom: none;
    }
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    .product-listing-dashboard__arrows {
      margin-right: 32px;
      .product-listing-dashboard__main-prev {
        padding-right: 1.5rem;
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    margin-right: 15px;

    img {
      display: block;
    }
  }
  &__add {
    margin-left: 32px;
    cursor: pointer;
  }
  &__view-icon {
    cursor: pointer;
  }
  &__pop-up {
    position: absolute;
    visibility: hidden;
    top: 0;
    right: 0;
  }
  &__pop-up-visible {
    visibility: visible;
  }

  & .tile__player {
    top: -15px
  }
}
